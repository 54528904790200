import { toast } from 'react-toastify';

const closeButton = false;
const timeClose = 12000;
const className = 'rotate'
const pauseOnHover =  true;
const draggable =  false;
const progress =  undefined;
const closeOnClick = false;
const position= "top-center";
const autoClose= false;
const hideProgressBar= false;
const closeOnClick2= false;
const pauseOnHover2= true;

const defaultToast = {
    closeButton: closeButton,
    type: toast.TYPE.DEFAULT,
    className: className,
    autoClose: timeClose,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    closeOnClick: closeOnClick

}

const successToast = {
    closeButton: closeButton,
    type: toast.TYPE.SUCCESS,
    className: className,
    autoClose: timeClose,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    closeOnClick: closeOnClick
}

const warningToast = {
    closeButton: closeButton,
    type: toast.TYPE.WARNING,
    className: className,
    autoClose: timeClose,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    closeOnClick: closeOnClick
}

const errorToast = {
    closeButton: closeButton,
    type: toast.TYPE.ERROR,
    className: className,
    autoClose: timeClose,
    pauseOnHover: pauseOnHover,
    draggable: draggable,
    progress: progress,
    closeOnClick: closeOnClick
}


const successToastfijo = {
    closeButton: closeButton,
    type: toast.TYPE.SUCCESS,
    position:position,
    className: className,
    autoClose: autoClose,
    pauseOnHover: pauseOnHover2,
    hideProgressBar:hideProgressBar,
    draggable: draggable,
    progress: progress,
    closeOnClick: closeOnClick2
}


export {
    defaultToast,
    successToast,
    warningToast,
    errorToast,
    successToastfijo
}