
import firebase from "firebase/compat/app"
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: "AIzaSyCRk92ZMi2sYtHWuH2p2hQC7qbLmUND4uc",
    authDomain: "asambleageneral-d7e87.firebaseapp.com",
    databaseURL: "https://asambleageneral-d7e87-default-rtdb.firebaseio.com",
    projectId: "asambleageneral-d7e87",
    storageBucket: "asambleageneral-d7e87.appspot.com",
    messagingSenderId: "994241614597",
    appId: "1:994241614597:web:f98d75bba837edbf229d6c",
    measurementId: "G-ETN4HKZQ7S"
    // apiKey: "AIzaSyCeZjkP237icX5T3NZLPHPClbBGF6qmSnc",
    // authDomain: "asambleageneral-314d8.firebaseapp.com",
    // databaseURL: "https://asambleageneral-314d8-default-rtdb.firebaseio.com",
    // projectId: "asambleageneral-314d8",
    // storageBucket: "asambleageneral-314d8.appspot.com",
    // messagingSenderId: "5284122877",
    // appId: "1:5284122877:web:1855c4be2f141c78c3ee94",
    // measurementId: "G-77LCZ83BY2"
    };


const firebaseApp = firebase.initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
          scope: '/firebase-push-notification-scope',
        });
      });
  }
  throw new Error('El navegador no soporta el servicio.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker()
    .then((serviceWorkerRegistration) =>
      getToken(messaging, { vapidKey:"BEFzxhJe3S6hLzZU614z1IUPtOA1xZW1QFYi9jnrNb_dPg0T0W5ZBiPSmIdzDJ9TdzZxtfW8ZqXUzO8z8hFbDUs", serviceWorkerRegistration }));

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));