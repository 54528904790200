import types from "../types/type"

const initialState = null

const AssemblyReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case (types.setAssembly):
            return payload
        case (types.resetAssembly):
            return initialState
        default:
            return state
    }
}

export default AssemblyReducer