import types from "../types/type"

const initialState = {
    Login: null
}

const LoginReducer = (state = initialState, { type, payload = null }) => {

    switch (type) {
        case (types.login):
            return { Login: true, ...payload }
        case (types.logout):
            return initialState
        default:
            return state
    }
}

export default LoginReducer