import { combineReducers } from "redux";
import AssemblyReducer from "./assembly.reducer";
import LoginReducer from "./login.reducer";
import BlockScreenReducer from './block-screen.reducer'
import ToastReducer from "./toast.reducer";
import AppConfig from "./appConfig.reducer";

const allReducers = combineReducers({
    assemblyReducer: AssemblyReducer,
    loginReducer: LoginReducer,
    toastReducer: ToastReducer,
    blockScreenReducer: BlockScreenReducer,
    appConfig: AppConfig
})

export default allReducers