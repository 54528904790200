import types from "../types/type";

const initialState = {
    blockScreen: false
};

const BlockScreenReducer = (state = initialState, { type }) => {
    switch (type) {
        case types.blockScreen:
            return { blockScreen: true };
        case types.unlockScreen:
            return initialState;
        default:
            return state;
    }
}

export default BlockScreenReducer;