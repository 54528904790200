import types from "../types/type"

const AppConfig = (state = {}, { type, payload = null }) => {

    switch (type) {
        case (types.setAppConfig):
            return payload
        default:
            return state
    }
}

export default AppConfig