import types from "../types/type";

const initialState = {
    typeToast: "",
    messageToast: "mensaje",
    show: false
}

const ToastReducer = (state = initialState, { type, payload = null }) => {

    switch (type) {
        case (types.toast):
            return { ...payload };
        default:
            return state;
    }
}

export default ToastReducer;
