const types = {

    setAssembly: '[config] setAssembly',
    resetAssembly: '[config] resetAssembly',
    login: '[login] login',
    logout: '[login] logout',
    toast: '[toast] toast',
    setAppConfig: '[appConfig] setAppConfig'
}

export default types