import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastData } from '../../actions/toast.action';
import '../../assets/css/scrambled.css';

import {
  defaultToast,
  successToast,
  warningToast,
  errorToast,
  successToastfijo
} from './typeToasts';

function CustomToast({ text = "successs text", typeToast = "", show = false }) {
  const dispatch = useDispatch();
  var finalToast = {}
  useEffect(() => {
    if (show) {
      switch (typeToast) {
        case "success":
          finalToast = successToast
          break;
          case "successf":
            finalToast = successToastfijo
            break;
        case "warning":
          finalToast = warningToast
          break;
        case "error":
          finalToast = errorToast
          break;
        default:
          finalToast = defaultToast
      }
      customToast();
    }
  }, [show])

  const customToast = () => {
    toast(text, { ...finalToast, render: { text }, className: "" });
    dispatch(toastData({ typeToast: "", messageToast: '', show: false }));
  };

  return (
    <div>
      <ToastContainer style={{ marginTop: '50px' }} />
    </div>
  );
}

export default CustomToast