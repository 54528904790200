/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import "./assets/css/container.css";
import desktopImage from "./assets/imgs/FondoPrincipal.png";
// import desktopImage from './assets/imgs/2023.png';
import CustomToast from "./common/toast/toast";
import { getFirebaseToken, onForegroundMessage } from "./functions/firebasen";
import { ToastContainer, toast } from "react-toastify";
import logo from "./assets/imgs/logo.png";
import { GrAppleAppStore } from "react-icons/gr";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
const appStoreLink =
  "https://apps.apple.com/gt/app/cooperativa-salcaja-r-l/id1548803266";

// Pages
const Streaming = React.lazy(() => import("./views/streaming/Container"));
const MenuContainer = React.lazy(() =>
  import("./views/menu/container/TheLayout")
);
const PageNotFound = React.lazy(() =>
  import("./views/pageNotFound/PageNotFound")
);
const Help = React.lazy(() => import("./views/help/Index"));
const HelpView = React.lazy(() => import("./views/help/View"));
const SplashScreen = React.lazy(() =>
  import("./views/splashScreen/splashScreen")
);
// const LoginContainer
const App = () => {
  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          //Ok
        }
      })
      .catch((err) =>
        console.error("Ocurrió un error al recibir el token de firebase: ", err)
      );
  };

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        console.log("Received foreground message: ", payload);
      })
      .catch((err) =>
        console.log(
          "Ocurrió un error al recibir la notificación en segundo plano: ",
          err
        )
      );
  }, []);

  useEffect(() => {
    handleGetFirebaseToken();
  }, []);

  return (
    <Router>
      <div className="App">
        <main className="App-content">
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                name="splash"
                render={(props) => <SplashScreen {...props} />}
              />
              <Route
                path="/"
                name="MainContainer"
                render={(props) => <MainContainer {...props} />}
              />
            </Switch>
          </React.Suspense>
        </main>
      </div>
    </Router>
  );
};
{
  /*     <button
        className="btn-primary"
        onClick={() => toast(<ToastifyNotification title="New Message" body="Hi there!" />)}
      >
        Show toast notification
      </button> */
}
// const getLockScreenUi = setLock => {
//   return (
//     <React.Fragment>

//       <div className="react-lock-screen__ui">
//         <img
//           width="32"
//           src="https://cdn3.iconfinder.com/data/icons/wpzoom-developer-icon-set/500/102-256.png"
//           alt="lock"
//         />
//         <p>Just to be safe, we locked the screen</p>
//         <button onClick={() => setLock(false)}>unlock</button>
//       </div>
//     </React.Fragment>
//   );
// };

const MainContainer = () => {
  const selector = useSelector((current) => current.toastReducer);
  return (
    <Router>
      <CustomToast
        text={selector.messageToast}
        typeToast={selector.typeToast}
        show={selector.show}
      />
      <div className="App container-fluid">
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/menu"
              name="menu"
              render={(props) => <MenuContainer {...props} />}
            />
            <Route
              path="/streaming"
              exact
              name="Asamblea Page"
              render={(props) => <Streaming {...props} />}
            />
            <Route
              path="/pagenotfound"
              exact
              name="page not found"
              render={(props) => <PageNotFound {...props} />}
            />
            <Route
              path="/help"
              name="ayauda"
              exact
              render={(props) => <Help {...props} />}
            />
            <Route
              path="/helpvideo"
              exact
              name="ayuda vista"
              render={(props) => <HelpView {...props} />}
            />
            <Redirect from="/" to="/pagenotfound" />
          </Switch>
        </React.Suspense>
      </div>
    </Router>
  );
};

export default App;
